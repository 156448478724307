<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          Manajemen Kupon
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />

    <c-flex
      mb="33px"
      justify="space-between"
      align="center"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Manajemen Kupon
      </c-heading>
      <c-button
        h="auto"
        py="12px"
        px="28px"
        color="#927102"
        border-radius="24px"
        border-color="transparent"
        bg="secondary.800"
        variant-color="inherit"
        font-size="16px"
        line-height="24px"
        variant="solid"
        as="router-link"
        :to="{ name: 'superadmin.coupons.add' }"
      >
        <c-image
          :src="require('@/assets/icon-plus.svg')"
          alt="icon"
          display="inline-block"
          mr="10px"
        />
        Tambah Kupon
      </c-button>
    </c-flex>
    <c-box
      background="#FFFFFF"
      border="1px solid #F2F2F2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="12px"
      class="container"
    >
      <c-flex
        py="25px"
        px="27px"
        border-bottom="1px solid #C4C4C4"
      >
        <c-flex
          justify="space-between"
          align="center"
          flex-grow="1"
        >
          <c-heading
            as="h3"
            font-family="Montserrat"
            font-weight="600"
            font-size="16px"
            line-height="24px"
          >
            List of Diet Kupon
          </c-heading>
          <c-menu
            :close-on-select="true"
            @blur="true"
          >
            <c-menu-button
              border-radius="40px"
              background-color="superLightGray.900"
              pl="20px"
              pr="30px"
              py="10px"
              h="auto"
              color="gray.900"
              font-size="14px"
              line-height="21px"
              font-family="Roboto"
              font-weight="normal"
              :display="['none', 'flex']"
            >
              <c-image
                :src="require('@/assets/ic-filter.svg')"
                alt="icon"
                display="inline-block"
                margin-right="5px"
              />
              Filter
            </c-menu-button>
            <c-menu-list
              z-index="10"
              min-width="300px"
            >
              <c-box
                px="24px"
                mt="24px"
              >
                <c-form-control mb="24px">
                  <c-form-label
                    font-size="14px"
                    color="#555555"
                    font-weigh="400"
                    font-family="Roboto"
                  >
                    Start Date
                  </c-form-label>
                  <c-input-group size="md">
                    <c-input
                      v-model="startDate"
                      v-chakra="{
                        '::-webkit-calendar-picker-indicator': {
                          background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,
                          cursor: 'pointer',
                        },
                      }"
                      type="date"
                      error-border-color="red.300"
                      placeholder="Masukkan Start Date"
                    />
                  </c-input-group>
                </c-form-control>
                <c-form-control mb="24px">
                  <c-form-label
                    font-size="14px"
                    color="#555555"
                    font-weigh="400"
                    font-family="Roboto"
                  >
                    End Date
                  </c-form-label>
                  <c-input-group size="md">
                    <c-input
                      v-model="endDate"
                      v-chakra="{
                        '::-webkit-calendar-picker-indicator': {
                          background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,
                          cursor: 'pointer',
                        },
                      }"
                      type="date"
                      error-border-color="red.300"
                      placeholder="Masukkan End Date"
                    />
                  </c-input-group>
                </c-form-control>
              </c-box>
              <c-menu-divider />
              <c-flex justify="flex-end">
                <c-button
                  variant-color="primary"
                  size="sm"
                  mx="24px"
                  my="8px"
                  @click="applyFilter"
                >
                  Apply
                </c-button>
              </c-flex>
            </c-menu-list>
          </c-menu>
        </c-flex>
        <c-divider
          mx="23px"
          orientation="vertical"
        />
        <c-box
          flex-basis="248px"
          align-self="center"
        >
          <c-form-control
            pos="relative"
            w="100%"
          >
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
              pos="absolute"
              top="10px"
              left="15px"
              z-index="2"
            />
            <c-input
              id="search"
              v-model="search"
              v-chakra="{
                '&::placeholder': {
                  color: 'gray.900',
                },
              }"
              type="text"
              w="100%"
              placeholder="Pencarian"
              background="superLightGray.900"
              border-radius="6px"
              padding-left="50px"
              pos="relative"
              z-index="1"
              @keyup="onSearch"
            />
          </c-form-control>
        </c-box>
      </c-flex>

      <c-box>
        <c-box>
          <c-grid
            w="100%"
            :template-columns="'repeat(' + headings.length + ', 0fr)'"
          >
            <c-box
              v-for="(item, index) in headings"
              :key="'heading-' + index"
              :w="item.width"
              py="11px"
              px="14px"
              color="primary.400"
              font-weight="bold"
              font-size="12px"
              line-height="18px"
              text-transform="uppercase"
              bg="rgba(0, 140, 129, 0.1)"
            >
              {{ item.label }}
            </c-box>
          </c-grid>

          <c-grid
            v-for="(item, index) in items"
            :key="item.id"
            w="100%"
            max-w="100%"
            :template-columns="'repeat(' + headings.length + ', 0fr)'"
            border-bottom="1px solid #C4C4C4"
          >
            <c-box
              :w="headings[0].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ numberingRow(index) }}
            </c-box>
            <c-box
              :w="headings[1].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.name }}
            </c-box>
            <c-box
              :w="headings[2].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.code }}
            </c-box>
            <c-box
              :w="headings[3].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.quota }}
            </c-box>
            <c-box
              py="16px"
              px="14px"
              :w="headings[4].width"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.startAt }} - {{ item.endAt }}
            </c-box>
            <c-box
              py="16px"
              px="14px"
              :w="headings[5].width"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ getDiscount(item) }}
            </c-box>
            <c-box
              :w="headings[6].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              <c-button
                h="30px"
                color="primary.400"
                border-radius="24px"
                border-color="primary.400"
                font-size="12px"
                line-height="18px"
                variant="outline"
                as="router-link"
                :to="{
                  name: 'superadmin.coupons.edit',
                  params: { id: item.id },
                }"
              >
                Edit
              </c-button>
            </c-box>
          </c-grid>
          <c-flex
            v-if="!isEmpty"
            justify="space-between"
            py="28px"
            px="40px"
          >
            <c-flex align="center">
              <c-text
                color="gray.900"
                font-size="14px"
                line-height="21px"
              >
                Show:
              </c-text>
              <c-select
                v-model="perPage"
                min-w="120px"
                border-radius="6px"
                font-size="14px"
                ml="13px"
                size="md"
                @change="onSearch"
              >
                <option value="5">
                  5 Rows
                </option>
                <option value="10">
                  10 Rows
                </option>
                <option value="15">
                  15 Rows
                </option>
                <option value="20">
                  20 Rows
                </option>
              </c-select>
            </c-flex>

            <c-flex
              justify-content="flex-end"
              w="100%"
            >
              <c-flex
                justify-content="center"
                align="center"
              >
                <c-button
                  p="0"
                  mr="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  border-radius="30px"
                  background-color="transparent"
                  :is-disabled="currentPage === 1"
                  @click="prevPage"
                >
                  <c-icon
                    name="chevron-left"
                    size="24px"
                  />
                </c-button>

                <c-button
                  v-for="page_ in pages"
                  :key="page_"
                  v-chakra="{
                    ':hover': {
                      bg: 'rgba(0, 140, 129, 0.3)',
                      color: 'primary.400',
                    },
                  }"
                  p="0"
                  m="0"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  mx="4px"
                  border-radius="30px"
                  background-color="rgba(0, 140, 129, 0.3)"
                  color="primary.400"
                  :bg="page_ === currentPage ? ['rgba(0, 140, 129, 0.3)'] : ['transparent']"
                  as="router-link"
                  :to="{ name: $route.name, query: { ...$route.query, page: page_ } }"
                >
                  {{ page_ }}
                </c-button>

                <c-button
                  p="0"
                  ml="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  border-radius="30px"
                  background-color="transparent"
                  :is-disabled="pages.length === page"
                  @click="nextPage"
                >
                  <c-icon
                    name="chevron-right"
                    size="24px"
                  />
                </c-button>
              </c-flex>
            </c-flex>
          </c-flex>
          <c-flex
            v-if="isEmpty"
            py="80px"
            px="20px"
            justify-content="center"
          >
            <c-box>
              <c-image
                :src="require('@/assets/empty-nutritionists.svg')"
                alt="empty"
              />
              <c-text
                color="gray.900"
                font-family="Roboto"
                font-size="24px"
                margin-top="20px"
              >
                Kupon tidak ditemukan
              </c-text>
            </c-box>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'ManagementCouponsPage',
  components: {},
  mixins: [generalMixin],
  data() {
    return {
      headings: [
        { label: 'no', width: '75px' },
        { label: 'Nama Kupon', width: '200px' },
        { label: 'Kode', width: '150px' },
        { label: 'Kuota', width: '125px' },
        { label: 'start-end', width: '140px' },
        { label: 'potongan', width: '92px' },
        { label: 'action', width: '110px' },
      ],
      page: 1,
      perPage: '10',
      search: this.$route.query.q ?? '',
      startDate: '',
      endDate: '',
      timer: null,
    }
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      items: 'suCoupons/items',
      meta: 'suCoupons/meta',
    }),
    isEmpty() {
      return this.items.length === 0
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.meta.total ?? 1
      let perPage = parseInt(this.perPage) ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.page = route.query.page ? parseInt(route.query.page) : 1
        let params = new URLSearchParams()
        params.set('q', this.search)
        params.set('startDate', this.startDate)
        params.set('endDate', this.endDate)
        params.set('page', this.page)
        params.set('perpage', this.perPage)
        this.loadCoupons(params)
      },
    },
  },
  methods: {
    ...mapActions({
      loadCoupons: 'suCoupons/listCouponsSuperadmin',
    }),
    getDiscount(item) {
      if (item.type === 'percent') {
        return `${item.totalDiscount}%`
      }
      return `Rp${Number(item.totalDiscount).toLocaleString('id')}`
    },
    applyFilter() {
      this.onSearch()
      const container = document.querySelector('.container')
      if (container) {
        container.click()
      }
    },
    onSearch() {
      this.page = 1
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            q: this.search,
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.page,
            perpage: this.perPage,
          },
        })
      }, 800)
    },
    numberingRow(index) {
      return this.page * this.perPage - this.perPage + index + 1
    },
    prevPage() {
      this.page -= 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
    nextPage() {
      this.page += 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
  },
}
</script>
